import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Box,
  IconButton,
} from '@material-ui/core';
import {DataGrid, GridToolbar} from '@material-ui/data-grid';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import PageContainer from '../../components/container/PageContainer';
import http from '../../lib/http';
import ExcelExportActions from '../../components/button/ExcelExport';


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const BCrumb = [
  {
    to: '/',
    title: 'Jadwal',
  },
  {
    title: 'Daftar Peserta',
  },
];

const Users = () => {
  const [users, setUsers] = React.useState([]);
  const [loadingData, setLoadingData] = React.useState();
  const [schedule, setSchedule] = React.useState({
    name: "",
    facilitator: ""
  })
  const [dataExport, setDataExport] = React.useState([]);
  const params = useParams()

  function fetchUsers() {
    http.get(`admin/${params.slug}/users`, {params: {
        page: 0,
        per_page: 500
    }}).then((response) => {
        setUsers(response.data.data)
        // setTotal(response.data.meta.total)
        setSchedule(response.data.meta.schedule)
    })
  }

  async function getAllUsers() {
    await http.get(`admin/${params.slug}/allusers`).then((res) => {
      setDataExport(res.data.data);
      // setUsers(res.data.data);
    });
  }

  React.useEffect(() => {
    setLoadingData(true);
    if (users.length > 0)
        setUsers([])

    if (params.slug) {
      fetchUsers();
      getAllUsers();
    }
    setLoadingData(false);
  }, [/* page, rowsPerPage, */ params.slug])

  // const deleteRow = (_id) => {
  //   if (window.confirm("Hapus?"))
  //   http.delete(`admin/user/${_id}`).then(() => {
  //     fetchUsers();
  //   })
  // }

  const viewDetail = (id) => {
    const url = `https://kjr.tarbiyahsunnah.com/detail/${id}`
    window.open(url, '_blank').focus();
  }

  const columns = [
    { field: '_id', headerName: 'ID', hide: true },
    { field: 'code', headerName: 'Kode', hide: true },
    { field: 'name', headerName: 'Nama', width:250 },
    { field: 'age', headerName: 'Usia', width:115 },
    { field: 'gender', headerName: 'Jenis Kelamin', width:178 },
    { field: 'whatsapp', headerName: 'Whatsapp', width:180 },
    { field: 'email', headerName: 'Email', width:205 },
    { field: 'city', headerName: 'Domisili', width:180 },
    { field: 'address', headerName: 'Alamat', width:300 },
    { field: 'transport', headerName: 'Moda Transport', width:190 },
    { field: 'econ', headerName: 'Kontak Darurat' , width:180},
    { 
      type: 'string',
      field: 'mahrams',
      headerName: 'Mahram',
      width: 200,
      valueFormatter: (param) => {
        let infoMahram;
        if (param.row.mahrams === undefined) {
          infoMahram = "-";
        }
        else {
          infoMahram = `${param.row.mahrams.name}
          ${param.row.mahrams.whatsapp}`; 
        }
        
        return infoMahram;
      },
    },
    {
      field: 'command',
      header: 'Opsi',
      width: 350,
      renderCell: (row) => {
        return(
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button 
              color="info"
              onClick={() => viewDetail(row.id)}>
              Lihat Detail
            </Button>
            &nbsp;
          </ButtonGroup>
        );
      },  
    }
  ];

  return (
    <PageContainer title="Jadwal Kajian" description="this is Pagination Table page">
      {/* breadcrumb */}
      <Breadcrumb title={schedule.name.concat(' - ').concat(schedule.facilitator)} items={BCrumb} />
      {/* end breadcrumb */}
      <Card>

        <CardContent>
          <Box
            sx={{
              overflow: {
                xs: 'auto',
                sm: 'unset',
              },
            }}
          >
            {dataExport.length > 0 ?
            <ExcelExportActions data={dataExport}/> : ''} 
            <div style={{ height: 650, width: '100%' }}>
              <DataGrid 
                rows={users} 
                columns={columns} 
                scrollbarSize={50}
                sx={{ overflowX: 'scroll' }}
                components={{Toolbar:GridToolbar,}}
                getRowId={(row) => row._id}
                pageSizeOptions={[250]}
                loading={loadingData}
              />
            </div>
            
          </Box>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default Users;
