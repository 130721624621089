import ReactExport from "react-export-excel";
import * as React from 'react';
import {Button} from '@material-ui/core';

const {ExcelFile} = ReactExport;
const {ExcelSheet} = ReactExport.ExcelFile;
const {ExcelColumn} = ReactExport.ExcelFile;

function ExcelExportActions(props) {
    // eslint-disable-next-line react/prop-types
    const [exportData] = React.useState(props.data);
    const flatData = exportData.map((data) => {
        if(data.mahrams){
            return { 
                ...data,
                mahramName: (data.mahrams.name?data.mahrams.name:""),
                mahramGender: (data.mahrams.gender?data.mahrams.gender:""),
                mahramStatus: data.mahrams.status,
                mahramWa: data.mahrams.whatsapp,
            };
        } 
        return { ...data };
        
    });

    return(
      <ExcelFile element={<Button variant="contained">Download Data</Button>}>
        <ExcelSheet data={flatData} name="Data Peserta">
          <ExcelColumn label="Nama" value="name"/>
          <ExcelColumn label="WA" value="whatsapp"/>
          <ExcelColumn label="Gender" value="gender"/>
          <ExcelColumn label="Usia" value="age"/>
          <ExcelColumn label="email" value="email"/>
          <ExcelColumn label="Daerah Asal" value="city"/>
          <ExcelColumn label="Nama Mahram" value="mahramName"/>
          <ExcelColumn label="Gender Mahram" value="mahramGender"/>
          <ExcelColumn label="WA Mahram" value="mahramWA"/>
          <ExcelColumn label="Status Keikutsertaan Mahram" value="mahramStatus"/>
        </ExcelSheet>
      </ExcelFile>
    );
  }

  export default ExcelExportActions;